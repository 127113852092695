<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="subjects"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'assiduity'">
          <b-badge variant="primary">
            {{ props.row.assiduity }}%
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'ds'">
          <b-badge variant="primary">
            {{ props.row.ds }}%
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'exam'">
          <b-badge variant="primary">
            {{ props.row.exam }}%
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'coefficient'">
          <b-badge variant="warning">
            {{ props.row.coefficient }}
          </b-badge>
        </span>

        <!-- Column: Common -->
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->

    </vue-good-table>
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Consultation des notes :
    </h4>
    <vue-good-table
      :columns="columnss"
      :rows="students"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-input
                  v-model="props.row.note"
                  placeholder="Assiduite"
                  @change="setNotes(props.row.student.id, props.row.group.id, subjectIddd ,props.row.note, year, props.row.semester)"
                />
              </div>
            </div>

          </span>

        </span>
        <span v-else-if="props.column.field === 'ds_presence'">
          <span>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-input
                  v-model="presence"
                  placeholder="Présent"
                  disabled
                />
              </div>
            </div>

          </span>

        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              <b-button
                variant="primary"
                type="submit"
                class="d-flex align-items-center"
                @click.prevent="addNote"
              >
                Enregistrer les notes
              </b-button>
            </span>

          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BBadge, BFormInput, BPagination,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
// import vSelect from 'vue-select'

// import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BButton,
    // vSelect,
    BBadge,
    BFormInput,
    BPagination,

  },
  data() {
    return {
      presence: 'Présent',
      load: 'false',
      pageLength: 15,
      user: {},
      academicYear: {},
      academicYears: [],
      semester: '',
      semesters: ['semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      columns: [
        {
          label: 'Semestre',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Unité',
          field: 'unity.label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Matière',
          field: 'label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Coef',
          field: 'coefficient',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'Crédit',
          field: 'credit',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Evaluation',
          field: 'exam_mode',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Assiduité',
          field: 'assiduity',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'DS',
          field: 'ds',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Examen',
          field: 'exam',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
      ],
      columnss: [
        {
          label: 'N°',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Identité',
          field: 'student.cin',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Nom & Prénom',
          field: 'student.username',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Présence DS',
          field: 'ds_presence',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'DS',
          field: 'action',
        },

      ],
      subjectIddd: '',
      noteStudents: [],
      paiements: [],
      inscriptions: [],
      students: [],
      discounts: [],
      notes: [],
      parcour: {},
      searchTerm: '',
      currentAcademicYear: {},
      semester1: [],
      semester2: [],
      groups: [],
      group: {},
      subjects: [],
      subjectt: [],
      subject: {},
      student: {},
      ds: '',
      year: '',
    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },

  },
  created() {
    this.getStudents()
    this.getSubject()
    this.user = storeAuth.state.user
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
    this.year = this.currentAcademicYear.id
    this.subjectIddd = this.$route.params.subjectIddd
  },
  methods: {

    setNotes(id, groupId, subjectIddd, note, year, semester) {
      const item = {
        id,
        groupId,
        subjectIddd,
        note,
        year,
        semester,
      }
      const check = this.noteStudents.find(student => student.id === item.id)
      const index = this.noteStudents.findIndex(student => student.id === item.id)
      if (check === undefined) {
        this.noteStudents.push(item)
      } else {
        this.noteStudents.splice(index, 1, item)
      }
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    async addNote() {
      try {
        await axios
          .post(
            '/api/notes/affect-note-assiduite/',
            {
              notes: this.noteStudents,
            },
          )
        this.$router.push('/notes')
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Note ajouté avec succés')
        }, 1000)
      } catch (err) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', err.toString())
        }, 1000)
      }
    },

    async getStudents() {
      this.load = 'true'
      // const { id } = this.$route.params.id
      const response = await axios.get('/api/inscriptions/', {
        params: {
          group_id: this.$route.params.id,
        },
      })
      // const result = response.data
      this.students = response.data
      this.load = 'false'
    },

    async getSubject() {
      this.load = 'true'
      // const { id } = this.subjectid
      const response = await axios.get('/api/subjects/', {
        params: {
          id: this.$route.params.subjectIddd,
        },
      })
      // const result = response.data
      this.subjects = response.data
      this.load = 'false'
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
